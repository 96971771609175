import { Stack, useBreakpointValue } from '@chakra-ui/react'

import { MARKETING_HEADER_HEIGHT } from '../../constants'

export type HeaderMode = 'dark' | 'light'

import { HEADER_BG_COLOR } from './colorConstants'
import { MarketingHeaderDesktop } from './MarketingHeaderDesktop'
import { MarketingHeaderMobile } from './MarketingHeaderMobile'
import { useMarketingHeaderUIContext } from './MarketingHeaderUIContext'
export const MarketingHeader = () => {
  const { mode, isFixed } = useMarketingHeaderUIContext()
  const isMobileScreenSize = useBreakpointValue({ base: true, md: false })
  const shouldUseFixedHeader = isFixed && !isMobileScreenSize
  const shouldShowMobileHeader = useBreakpointValue({ base: true, md: false })

  return (
    <Stack
      w="100%"
      py={3}
      px={[6, 6, 12]}
      h={MARKETING_HEADER_HEIGHT}
      pr={[4, 6, 12]}
      zIndex="docked"
      top={['auto', 'auto', '0']}
      position={shouldUseFixedHeader ? 'fixed' : 'relative'}
      justifyContent="center"
      backgroundColor={
        shouldUseFixedHeader ? HEADER_BG_COLOR[mode] : 'transparent'
      }
    >
      {shouldShowMobileHeader ? (
        <MarketingHeaderMobile />
      ) : (
        <MarketingHeaderDesktop />
      )}
    </Stack>
  )
}
